import React from "react";

const IconUploadFigure = () => {
  return (
    <svg width="100" height="101" viewBox="0 0 100 101" fill="none">
      <rect
        width="100"
        height="100"
        transform="translate(0 0.435059)"
        fill="white"
      />
      <path
        d="M17.1899 40.8098C16.7314 39.4835 16.4824 38.0595 16.4824 36.5774C16.4824 29.4259 22.2798 23.6285 29.4313 23.6285C30.9416 23.6285 32.3916 23.8871 33.7393 24.3624C36.103 16.9205 43.0682 11.5284 51.2929 11.5284C60.2866 11.5284 67.7744 17.976 69.3861 26.5011C75.2566 26.6908 79.9575 31.5095 79.9575 37.4261C79.9575 38.6071 79.7702 39.7444 79.4237 40.8098"
        stroke="#3867FA"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M78.6524 67.0616V81.5335H64.8838V62.0803L73.2769 62.0803M78.6524 67.0616L73.2769 62.0803M78.6524 67.0616H73.2769V62.0803"
        stroke="#3867FA"
        strokeWidth="3"
      />
      <path
        d="M56.8839 58.9169V73.3887H43.1152V53.9356L51.5083 53.9356M56.8839 58.9169L51.5083 53.9356M56.8839 58.9169H51.5083V53.9356"
        stroke="#3867FA"
        strokeWidth="3"
      />
      <path
        d="M35.1153 67.0616V81.5335H21.3467V62.0803L29.7398 62.0803M35.1153 67.0616L29.7398 62.0803M35.1153 67.0616H29.7398V62.0803"
        stroke="#3867FA"
        strokeWidth="3"
      />
      <path
        d="M12.7363 74.656V89.3417H87.2631V74.656"
        stroke="#3867FA"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M50.0001 42.347V22.873M50.0001 22.873L42.9854 29.8878M50.0001 22.873L57.0148 29.8878"
        stroke="#3867FA"
        strokeWidth="3"
      />
      <rect
        x="70.6836"
        y="43.951"
        width="3"
        height="3"
        rx="1.5"
        fill="#3867FA"
      />
      <rect
        width="3"
        height="3"
        rx="1.5"
        transform="matrix(-1 0 0 1 25.6602 43.951)"
        fill="#3867FA"
      />
      <rect
        x="64.2148"
        y="43.951"
        width="3"
        height="3"
        rx="1.5"
        fill="#3867FA"
      />
      <rect
        width="3"
        height="3"
        rx="1.5"
        transform="matrix(-1 0 0 1 32.1289 43.951)"
        fill="#3867FA"
      />
      <rect
        width="3"
        height="3"
        rx="1.5"
        transform="matrix(-1 0 0 1 32.1289 37.8098)"
        fill="#3867FA"
      />
      <rect
        x="64.2148"
        y="37.8098"
        width="3"
        height="3"
        rx="1.5"
        fill="#3867FA"
      />
      <rect
        x="57.7461"
        y="43.951"
        width="3"
        height="3"
        rx="1.5"
        fill="#3867FA"
      />
      <rect
        width="3"
        height="3"
        rx="1.5"
        transform="matrix(-1 0 0 1 38.5977 43.951)"
        fill="#3867FA"
      />
      <rect
        x="77.1523"
        y="50.092"
        width="3"
        height="3"
        rx="1.5"
        fill="#3867FA"
      />
      <rect
        x="70.6836"
        y="50.092"
        width="3"
        height="3"
        rx="1.5"
        fill="#3867FA"
      />
      <rect
        width="3"
        height="3"
        rx="1.5"
        transform="matrix(-1 0 0 1 25.6602 50.092)"
        fill="#3867FA"
      />
      <rect
        width="3"
        height="3"
        rx="1.5"
        transform="matrix(-1 0 0 1 19.1914 50.092)"
        fill="#3867FA"
      />
      <rect
        x="77.1523"
        y="56.233"
        width="3"
        height="3"
        rx="1.5"
        fill="#3867FA"
      />
    </svg>
  );
};

export default IconUploadFigure;
