import React from "react";
import { IconProps } from "~/interface/icon";

const IconChartSmall = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <path d="M1.13,12 L1.13,5.60014401 C1.13,5.04785926 1.57771525,4.60014401 2.13,4.60014401 L2.88,4.60014401 C3.43228475,4.60014401 3.88,5.04785926 3.88,5.60014401 L3.88,12 C3.88,12.5522847 3.43228475,13 2.88,13 L2.13,13 C1.57771525,13 1.13,12.5522847 1.13,12 Z M5.63013501,12 L5.63013501,2 C5.63013501,1.44771525 6.07785026,1 6.63013501,1 L7.38013501,1 C7.93241976,1 8.38013501,1.44771525 8.38013501,2 L8.38013501,12 C8.38013501,12.5522847 7.93241976,13 7.38013501,13 L6.63013501,13 C6.07785026,13 5.63013501,12.5522847 5.63013501,12 Z M10.13027,12 L10.13027,9.20028801 C10.13027,8.64800326 10.5779853,8.20028801 11.13027,8.20028801 L11.88027,8.20028801 C12.4325548,8.20028801 12.88027,8.64800326 12.88027,9.20028801 L12.88027,12 C12.88027,12.5522847 12.4325548,13 11.88027,13 L11.13027,13 C10.5779853,13 10.13027,12.5522847 10.13027,12 Z"></path>
      </g>
    </svg>
  );
};

export default IconChartSmall;
