import React from "react";
import { IconProps } from "~/interface/icon";

const IconTreeSmall = ({ className }: IconProps) => {
  return (
    <svg className={className} width="14px" height="14px" viewBox="0 0 14 14">
      <path d="M4.875,1 C5.9109375,1 6.75,1.8390625 6.75,2.875 C6.75,3.66045573 6.26734818,4.33295039 5.58082446,4.61178146 L5.5875,4.62246094 L5.587,7.768 L5.74291977,7.71198179 C6.08350356,7.59933512 6.4754264,7.53653161 7.08859666,7.47507481 L7.08859666,7.47507481 L7.73964901,7.41079089 C8.62155633,7.31269905 8.99127205,7.19386958 9.22325702,6.9046437 C9.35811599,6.73805321 9.43468269,6.47950949 9.4477603,6.1285935 L9.4477603,6.1285935 C8.7486319,5.85455531 8.25,5.17325917 8.25,4.375 C8.25,3.3390625 9.0890625,2.5 10.125,2.5 C11.1609375,2.5 12,3.3390625 12,4.375 C12,5.2 11.465625,5.90078125 10.725,6.15390625 L10.866,6.099 L10.8749763,6.11575455 C10.8640501,6.72980454 10.7229935,7.23802492 10.4458665,7.64902024 L10.335554,7.79860286 C9.75378717,8.52065392 9.04708105,8.72457695 7.4623846,8.87102987 L7.4623846,8.87102987 L6.87874121,8.93162291 C6.42777607,8.98684306 6.18124909,9.0470448 5.96705345,9.15546479 C5.8336696,9.22309604 5.74111349,9.30809655 5.68902744,9.3920571 L5.68902744,9.3920571 L5.66506266,9.42517628 C6.30672365,9.72211779 6.75,10.3723312 6.75,11.125 C6.75,12.1609375 5.9109375,13 4.875,13 C3.8390625,13 3,12.1609375 3,11.125 C3,10.2859375 3.55078125,9.57578125 4.3125,9.334375 L4.199,9.375 L4.1625,9.29355469 L4.1625,4.62246094 L4.16713898,4.61095291 C3.48169749,4.33161993 3,3.65967882 3,2.875 C3,1.8390625 3.8390625,1 4.875,1 Z" />
    </svg>
  );
};

export default IconTreeSmall;
