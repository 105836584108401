import React from "react";
import { IconProps } from "~/interface/icon";

const ImgMatrixPrimary = ({ className }: IconProps) => {
  return (
    <svg fill="#3867FA" className={className} viewBox="0 0 150 150">
      <rect width="150" height="150" fill="white" />
      <path
        fillRule="evenodd"
        d="M15.3125 111.514V91.7446H36.0625V111.514H15.3125ZM36.0625 86.7446H15.3125V66.9756H36.0625V86.7446ZM41.0625 111.514V91.7446H61.8125V111.514H41.0625ZM41.0625 86.7446H61.8125V66.9756H41.0625V86.7446ZM66.8125 111.514V91.7446H87.5625V111.514H66.8125ZM92.5625 111.514H113.312V91.7446H92.5625V111.514ZM92.5625 86.7446H113.312V66.9756H92.5625V86.7446ZM92.5625 61.9756H113.312V42.2065H92.5625V61.9756ZM87.5625 42.2065V61.9756H66.8125V42.2065H87.5625ZM92.5625 37.2065H113.312V17.5142H92.5625V37.2065ZM87.5625 17.5142V37.2065H66.8125V17.5142H87.5625ZM87.5625 66.9756V86.7446H66.8125V66.9756H87.5625ZM36.0625 61.9756H15.3125V42.2065H36.0625V61.9756ZM36.0625 37.2065H15.3125V17.5142H36.0625V37.2065ZM41.0625 42.2065V61.9756H61.8125V42.2065H41.0625ZM61.8125 37.2065H41.0625V17.5142H61.8125V37.2065ZM10.3125 16.5142C10.3125 14.305 12.1034 12.5142 14.3125 12.5142H114.312C116.522 12.5142 118.312 14.305 118.312 16.5142V112.514C118.312 114.723 116.522 116.514 114.312 116.514H14.3125C12.1034 116.514 10.3125 114.723 10.3125 112.514V16.5142ZM129.25 29.6929H123.75V25.6929H130.25C131.907 25.6929 133.25 27.036 133.25 28.6929V50.0679H138.688C140.344 50.0679 141.688 51.411 141.688 53.0679V99.8179C141.688 101.475 140.344 102.818 138.688 102.818H123.75V98.8179H137.688V54.0679H133.25V78.2554C133.25 79.9122 131.907 81.2554 130.25 81.2554H123.75V77.2554H129.25V29.6929ZM98.8184 121.548V127.048L51.2559 127.048V121.548H47.2559V128.048C47.2559 129.705 48.599 131.048 50.2559 131.048H74.4434V135.486L29.6934 135.486V121.548H25.6934L25.6934 136.486C25.6934 138.143 27.0365 139.486 28.6934 139.486L75.4434 139.486C77.1002 139.486 78.4434 138.143 78.4434 136.486V131.048H99.8184C101.475 131.048 102.818 129.705 102.818 128.048V121.548H98.8184Z"
      />
    </svg>
  );
};

export default ImgMatrixPrimary;
