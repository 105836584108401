import React from "react";
import { IconProps } from "~/interface/icon";

const IconCheckSmall = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      color="#3867FA"
      fill="currentColor"
      fillRule="evenodd"
      height="14px"
      viewBox="0 0 14 14"
      width="14px"
    >
      <path d="M12.4154429,1.0113547 C12.224591,1.04867271 12.054896,1.16786509 11.9309769,1.3452957 L5.65000409,9.86217534 L2.4023085,6.19252769 C2.19541129,5.90548483 1.85267898,5.79330619 1.54131608,5.93356117 C1.2475177,6.06590412 1.04548431,6.39609589 1.00673898,6.78221593 C0.968554748,7.16274423 1.09435894,7.54262775 1.34042528,7.76666883 L5.16580803,12.0919798 C5.49517985,12.4615483 5.99866618,12.4297851 6.297284,12.0258112 L13.1217559,2.7694764 C13.3554654,2.46182239 13.4157028,1.9948864 13.274886,1.60334491 C13.1268172,1.19163933 12.7825202,0.94262347 12.4154429,1.0113547 Z" />
    </svg>
  );
};

export default IconCheckSmall;
