import React from "react";
import { IconProps } from "~/interface/icon";

const IconSuccessSmall = ({ className }: IconProps) => {
  return (
    <svg className={className} width="14px" height="14px" viewBox="0 0 14 14">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="currentColor">
          <path d="M7,0 C10.8660974,0 14,3.1339026 14,7 C14,10.8660974 10.8660974,14 7,14 C3.1339026,14 0,10.8660974 0,7 C0,3.1339026 3.1339026,0 7,0 Z M7,1.70731707 C9.91809773,1.70731707 12.2926829,4.08190227 12.2926829,7 C12.2926829,9.91809773 9.91809773,12.2926829 7,12.2926829 C4.08190227,12.2926829 1.70731707,9.91809773 1.70731707,7 C1.70731707,4.08190227 4.08190227,1.70731707 7,1.70731707 Z M9.98004138,4.61851823 C9.63128363,4.3472622 9.12866315,4.41008976 8.85740711,4.75884751 L8.85740711,4.75884751 L6.368,7.958 L5.22469505,6.52802174 L5.15117838,6.44781651 C4.86588193,6.17599451 4.41650271,6.15007573 4.10024396,6.40308273 C3.75523441,6.67909037 3.69929731,7.18252427 3.97530495,7.52753382 L3.97530495,7.52753382 L5.75308273,9.74975604 L5.83256202,9.83570861 C6.16909909,10.1505591 6.7161716,10.1179799 7.00925955,9.74115249 L7.00925955,9.74115249 L10.1203707,5.74115249 L10.1812525,5.65098051 C10.3791698,5.31023135 10.299736,4.86716959 9.98004138,4.61851823 Z" />
        </g>
      </g>
    </svg>
  );
};

export default IconSuccessSmall;
