import { BulkDownloadModal } from "./BulkDownloadModal";
export { BulkDownloadModal };

/*
 NOTE: component code should go in ReviewStep.tsx
 This index file is here so our imports are cleaner.
 This way we can use
 import { ReviewStep } from .../ReviewStep
 rather than
 import { ReviewStep } from .../ReviewStep/ReviewStep.tsx
*/
