import React from "react";
import { IconProps } from "~/interface/icon";

const IconSuccess = ({ className }: IconProps) => {
  return (
    <svg className={className} width="32px" height="32px" viewBox="0 0 32 32">
      <path d="M16,2 C23.7195666,2 30,8.28043337 30,16 C30,23.7195666 23.7195666,30 16,30 C8.28043337,30 2,23.7195666 2,16 C2,8.28043337 8.28043337,2 16,2 Z M16,4 C9.38323208,4 4,9.38323208 4,16 C4,22.6167679 9.38323208,28 16,28 C22.6167679,28 28,22.6167679 28,16 C28,9.38323208 22.6167679,4 16,4 Z M23.1148977,9.85527167 L23.1603558,9.99836713 C23.246638,10.3378006 23.1952024,10.7102042 23.0226138,10.9929703 L22.9412781,11.1083103 L14.2461319,22.1361047 C13.9581739,22.5003624 13.5052434,22.5649033 13.1563146,22.291308 L13.0613939,22.2046911 L8.17504379,17.0398553 C7.90117908,16.8066956 7.75720191,16.4001673 7.80115418,15.9905995 C7.84527102,15.5794979 8.07144405,15.2338565 8.38785536,15.1005826 C8.6669674,14.9830193 8.97363116,15.0489986 9.20692219,15.2754051 L9.29226274,15.3710543 L13.524892,19.9412707 L21.6925776,9.58248771 C21.827463,9.40189641 22.0078696,9.2834086 22.2052823,9.24731043 C22.5843844,9.18093697 22.9508603,9.42878237 23.1148977,9.85527167 Z" />
    </svg>
  );
};

export default IconSuccess;
