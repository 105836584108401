import React from "react";

const IconAnalyzeFigure = () => {
  return (
    <svg width="100" height="101" viewBox="0 0 100 101" fill="none">
      <rect
        width="100"
        height="100"
        transform="translate(0 0.435059)"
        fill="white"
      />
      <path
        d="M50.2429 71.6582H16.3711V23.7876H80.9463V71.6582H74.9351"
        stroke="#3867FA"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="55.0195"
        y="70.1582"
        width="3"
        height="3"
        rx="1.5"
        fill="#3867FA"
      />
      <rect
        x="35.457"
        y="48.4015"
        width="3"
        height="3"
        rx="1.5"
        transform="rotate(-90 35.457 48.4015)"
        fill="#3867FA"
      />
      <rect
        x="61.373"
        y="70.1582"
        width="3"
        height="3"
        rx="1.5"
        fill="#3867FA"
      />
      <rect
        x="35.457"
        y="42.048"
        width="3"
        height="3"
        rx="1.5"
        transform="rotate(-90 35.457 42.048)"
        fill="#3867FA"
      />
      <rect
        x="67.7266"
        y="70.1582"
        width="3"
        height="3"
        rx="1.5"
        fill="#3867FA"
      />
      <rect
        x="35.457"
        y="35.6945"
        width="3"
        height="3"
        rx="1.5"
        transform="rotate(-90 35.457 35.6945)"
        fill="#3867FA"
      />
      <circle
        cx="79.8381"
        cy="28.4365"
        r="10.6116"
        fill="white"
        stroke="#3867FA"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.3834 30.2871V29.7235C78.3834 29.2361 78.4875 28.8121 78.6957 28.4516C78.9039 28.0912 79.2847 27.7078 79.8381 27.3016C80.3712 26.9208 80.7215 26.6111 80.8891 26.3725C81.0617 26.1339 81.148 25.8673 81.148 25.5728C81.148 25.2428 81.0262 24.9915 80.7825 24.8189C80.5387 24.6462 80.1986 24.5599 79.7619 24.5599C79.0003 24.5599 78.1321 24.8087 77.1573 25.3063L76.3271 23.6384C77.4594 23.0037 78.6602 22.6864 79.9295 22.6864C80.9754 22.6864 81.8055 22.9377 82.4199 23.4404C83.0393 23.943 83.349 24.6132 83.349 25.451C83.349 26.0095 83.2221 26.4918 82.9682 26.898C82.7144 27.3042 82.232 27.7611 81.5212 28.2689C81.0338 28.6293 80.7241 28.9035 80.5921 29.0914C80.4651 29.2792 80.4017 29.5255 80.4017 29.8301V30.2871H78.3834ZM78.1397 32.8917C78.1397 32.4652 78.254 32.1428 78.4825 31.9245C78.7109 31.7062 79.0435 31.597 79.4801 31.597C79.9015 31.597 80.2265 31.7087 80.455 31.9321C80.6885 32.1555 80.8053 32.4754 80.8053 32.8917C80.8053 33.2928 80.6885 33.6102 80.455 33.8437C80.2214 34.0722 79.8965 34.1864 79.4801 34.1864C79.0536 34.1864 78.7236 34.0747 78.4901 33.8513C78.2565 33.6228 78.1397 33.303 78.1397 32.8917Z"
        fill="#3867FA"
      />
      <rect
        x="29.9707"
        y="62.9236"
        width="10.9754"
        height="18.5022"
        fill="white"
        stroke="#3867FA"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="26.2324"
        y="59.7393"
        width="18.4513"
        height="6.36871"
        fill="white"
        stroke="#3867FA"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0333 53.2842H67.1066"
        stroke="#3867FA"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.957 45.4971L28.957 53.2843"
        stroke="#3867FA"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.957 36.8669L44.957 53.2843"
        stroke="#3867FA"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.957 31.1863L52.957 53.2842"
        stroke="#3867FA"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.957 41.1583L60.957 53.2843"
        stroke="#3867FA"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconAnalyzeFigure;
