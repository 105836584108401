import React from "react";

const IconDoubleQuotes = () => {
  return (
    <svg width="37" height="27" viewBox="0 0 37 27" fill="none">
      <path
        d="M0.715454 17.4496C0.715454 14.7168 1.65647 11.8164 3.5385 8.74844C5.44631 5.65469 8.87522 2.83164 13.8252 0.279297L14.7533 2.01953C12.2783 3.77266 10.4479 5.24219 9.26194 6.42813C7.17366 8.56797 6.12952 10.6176 6.12952 12.577C6.12952 13.2473 6.32288 13.6855 6.7096 13.8918C7.09631 14.1238 7.43147 14.2398 7.71506 14.2398C8.69475 14.1109 9.33928 14.0465 9.64866 14.0465C11.2987 14.0465 12.6522 14.6523 13.7092 15.8641C14.7662 17.05 15.2948 18.4937 15.2948 20.1953C15.2948 22.1031 14.7147 23.7016 13.5545 24.9906C12.3944 26.2539 10.7959 26.8855 8.7592 26.8855C6.36155 26.8855 4.41506 26.0348 2.91975 24.3332C1.45022 22.6059 0.715454 20.3113 0.715454 17.4496ZM22.0623 17.4496C22.0623 14.5621 23.0678 11.5844 25.0787 8.51641C27.0897 5.44844 30.4541 2.70273 35.1721 0.279297L36.1002 2.01953C33.7799 3.64375 32.001 5.07461 30.7635 6.31211C28.5721 8.50352 27.4764 10.5402 27.4764 12.4223C27.4764 12.9637 27.6182 13.402 27.9018 13.7371C28.1854 14.0723 28.5721 14.2398 29.0619 14.2398C30.0416 14.1109 30.6862 14.0465 30.9955 14.0465C32.6198 14.0465 33.9604 14.6395 35.0174 15.8254C36.1002 17.0113 36.6416 18.468 36.6416 20.1953C36.6416 22.2062 36.0487 23.8305 34.8627 25.068C33.6768 26.2797 32.0655 26.8855 30.0287 26.8855C27.7084 26.8855 25.8006 26.0348 24.3053 24.3332C22.81 22.6059 22.0623 20.3113 22.0623 17.4496Z"
        fill="white"
      />
    </svg>
  );
};

export default IconDoubleQuotes;
