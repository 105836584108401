import React from "react";

const IconSampleFigure = () => {
  return (
    <svg width="93" height="83" viewBox="0 0 93 83" fill="none">
      <circle
        cx="46.1846"
        cy="41.6365"
        r="32.5"
        stroke="#3867FA"
        strokeWidth="3"
        strokeLinecap="round"
        strokeDasharray="0.1 10"
      />
      <circle
        cx="46.1846"
        cy="41.6365"
        r="32.5"
        stroke="#3867FA"
        strokeWidth="3"
        strokeLinecap="round"
        strokeDasharray="0.1 10"
      />
      <circle
        cx="45.9594"
        cy="41.4113"
        r="22.7749"
        stroke="#3867FA"
        strokeWidth="3"
      />
      <rect
        x="40.0342"
        y="26.1211"
        width="30.1518"
        height="14.5799"
        rx="7.28994"
        transform="rotate(45 40.0342 26.1211)"
        fill="white"
        stroke="#3867FA"
        strokeWidth="3"
      />
      <path
        d="M43.0283 42.7585L47.0488 43.5625"
        stroke="#3867FA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="46.96" cy="39.7578" r="1" fill="#3867FA" />
      <circle cx="58.2705" cy="34.1621" r="1" fill="#3867FA" />
      <circle cx="47.96" cy="26.8979" r="1" fill="#3867FA" />
      <circle cx="31.5986" cy="47.6924" r="1" fill="#3867FA" />
      <circle cx="47.46" cy="47.3635" r="1" fill="#3867FA" />
      <circle cx="77.1846" cy="31.6365" r="1.5" fill="#3867FA" />
      <circle
        cx="45.96"
        cy="2.01001"
        r="1.5"
        transform="rotate(90 45.96 2.01001)"
        fill="#3867FA"
      />
      <circle
        cx="33.9696"
        cy="4.09573"
        r="1.5"
        transform="rotate(71.7635 33.9696 4.09573)"
        fill="#3867FA"
      />
      <circle
        cx="13.8145"
        cy="18.6459"
        r="1.5"
        transform="rotate(34.2536 13.8145 18.6459)"
        fill="#3867FA"
      />
      <circle
        cx="8.02836"
        cy="14.7059"
        r="1.5"
        transform="rotate(34.2536 8.02836 14.7059)"
        fill="#3867FA"
      />
      <circle
        cx="83.9486"
        cy="53.7015"
        r="1.5"
        transform="rotate(16.2043 83.9486 53.7015)"
        fill="#3867FA"
      />
      <circle
        cx="90.6702"
        cy="55.6551"
        r="1.5"
        transform="rotate(16.2043 90.6702 55.6551)"
        fill="#3867FA"
      />
      <circle
        cx="77.2269"
        cy="51.7482"
        r="1.5"
        transform="rotate(16.2043 77.2269 51.7482)"
        fill="#3867FA"
      />
      <circle
        cx="69.9379"
        cy="9.98234"
        r="1.5"
        transform="rotate(-50.4638 69.9379 9.98234)"
        fill="#3867FA"
      />
      <circle cx="85.7266" cy="41.6365" r="1.5" fill="#3867FA" />
      <circle cx="78.7266" cy="41.6365" r="1.5" fill="#3867FA" />
      <circle
        cx="68.9474"
        cy="73.5609"
        r="1.5"
        transform="rotate(55.8213 68.9474 73.5609)"
        fill="#3867FA"
      />
      <circle
        cx="65.0148"
        cy="67.7699"
        r="1.5"
        transform="rotate(55.8213 65.0148 67.7699)"
        fill="#3867FA"
      />
      <circle
        cx="8.64038"
        cy="54.0959"
        r="1.5"
        transform="rotate(-19.878 8.64038 54.0959)"
        fill="#3867FA"
      />
      <circle
        cx="2.05738"
        cy="56.476"
        r="1.5"
        transform="rotate(-19.878 2.05738 56.476)"
        fill="#3867FA"
      />
      <circle
        cx="22.7513"
        cy="73.3028"
        r="1.5"
        transform="rotate(-51.9315 22.7513 73.3028)"
        fill="#3867FA"
      />
      <circle
        cx="27.0677"
        cy="67.7918"
        r="1.5"
        transform="rotate(-51.9315 27.0677 67.7918)"
        fill="#3867FA"
      />
      <circle
        cx="46.0527"
        cy="81.1843"
        r="1.5"
        transform="rotate(-90 46.0527 81.1843)"
        fill="#3867FA"
      />
      <circle
        cx="46.0527"
        cy="74.1843"
        r="1.5"
        transform="rotate(-90 46.0527 74.1843)"
        fill="#3867FA"
      />
    </svg>
  );
};

export default IconSampleFigure;
