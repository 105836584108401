import React from "react";
import { IconProps } from "~/interface/icon";

const IconInfo = ({ className }: IconProps) => {
  return (
    <svg className={className} width="32px" height="32px" viewBox="0 0 32 32">
      <g transform="translate(2.000000, 2.000000)">
        <path
          d="M14,0 C21.7195666,0 28,6.28043337 28,14 C28,21.7195666 21.7195666,28 14,28 C6.28043337,28 0,21.7195666 0,14 C0,6.28043337 6.28043337,0 14,0 Z M14,2 C7.38323208,2 2,7.38323208 2,14 C2,20.6167679 7.38323208,26 14,26 C20.6167679,26 26,20.6167679 26,14 C26,7.38323208 20.6167679,2 14,2 Z"
          id="Combined-Shape-Copy"
          transform="translate(14.000000, 14.000000) scale(-1, 1) rotate(-180.000000) translate(-14.000000, -14.000000) "
        ></path>
        <path
          d="M14.3060151,10.6868421 C15.3854618,10.6868421 16.2605263,11.5619066 16.2605263,12.6413534 L16.2605263,12.6413534 L16.2605263,21.2533835 C16.2605263,22.3328302 15.3854618,23.2078947 14.3060151,23.2078947 L14.3060151,23.2078947 L13.9045113,23.2078947 C12.8250645,23.2078947 11.95,22.3328302 11.95,21.2533835 L11.95,21.2533835 L11.95,12.6413534 C11.95,11.5619066 12.8250645,10.6868421 13.9045113,10.6868421 L13.9045113,10.6868421 Z M14.3060151,12.1868421 L13.9045113,12.1868421 C13.6534916,12.1868421 13.45,12.3903337 13.45,12.6413534 L13.45,12.6413534 L13.45,21.2533835 C13.45,21.5044031 13.6534916,21.7078947 13.9045113,21.7078947 L13.9045113,21.7078947 L14.3060151,21.7078947 C14.5570347,21.7078947 14.7605263,21.5044031 14.7605263,21.2533835 L14.7605263,21.2533835 L14.7605263,12.6413534 C14.7605263,12.3903337 14.5570347,12.1868421 14.3060151,12.1868421 L14.3060151,12.1868421 Z"
          id="Rectangle"
        ></path>
        <path
          d="M14.1054129,4.63157895 C14.7678763,4.63157895 15.4183604,4.90171549 15.8891527,5.37370574 C16.3602445,5.84000575 16.631579,6.49108873 16.631579,7.15804448 C16.631579,7.82739613 16.3602445,8.48027602 15.8867568,8.94867244 C15.426746,9.41407398 14.7753635,9.68421053 14.1054129,9.68421053 C13.4375586,9.68421053 12.7858767,9.41467295 12.3168814,8.94537809 C11.8478859,8.47638271 11.5789474,7.82500024 11.5789474,7.15774499 C11.5789474,6.49318513 11.8481854,5.84270112 12.3168814,5.37370574 C12.7885721,4.90171549 13.4402541,4.63157895 14.1054129,4.63157895 Z M14.1054129,6.12871043 C13.8343779,6.12871043 13.5687336,6.23922083 13.3758645,6.43179046 C13.1883862,6.61956831 13.0763784,6.89060331 13.0763784,7.15744551 C13.0763784,7.43057692 13.1853913,7.69622117 13.3758645,7.88669439 C13.7565115,8.26764083 14.4546138,8.26764083 14.8274741,7.88968926 C15.024536,7.69442426 15.1344474,7.42997794 15.1344474,7.15744551 C15.1344474,6.88760844 15.0248355,6.6240606 14.8334639,6.43448583 C14.6378994,6.23922083 14.3737525,6.12871043 14.1054129,6.12871043 Z"
          id="Combined-Shape-Copy-3"
        ></path>
      </g>
    </svg>
  );
};

export default IconInfo;
